import { Wraper } from "../helper";
import { Button, ButtonRound, Input, Terms } from "./index";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { useStore } from "../../store";
import { useRef } from "react";
import { useSuperfan } from "@pikabobalex/superfan-module";
import axios from "axios";

const LeaderBroad = (props) => {
  const { phaserRef } = props;
  const nav = useNavigate();
  return (
    <Wraper
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="leaderBroad"
    >
      <Broad phaserRef = {phaserRef}/>
    </Wraper>
  );
};

const Broad = (props) => {
  const {phaserRef} = props;
  const [show , setShow] = useState(false);
  const [canClick , setCanClick] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setCanClick(true);
    }, 1800);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#E5918A",
        borderRadius: "15px 15px", // Optional: Add border-radius for rounded corners
        width: "80vw", // Adjust the width as needed
        height: "80svh", // Adjust the height as needed
        position: "relative",
        boxShadow: "0 0 1rem rgba(0,0,0,0.2)",
        transition: "0.8s",
        border: "0.4rem solid #fdf4cf",
        marginTop: "5vh",
        pointerEvents : canClick ? "auto" : "none",
      }}
    >
      <div
        className="block"
        style={{
          height: "3%",
        }}
      />


      {/* start */}
      <section
        style={{
          position: "relative",
        }}
      >
        <A canClick = {canClick} show = {show} setShow = {setShow} phaserRef = {phaserRef}/>
        <B canClick = {canClick} show = {show} setShow = {setShow}/>
      </section>
    </div>
  );
};

const A = ({
  show,
  setShow,
  phaserRef,
  canClick
}) => {

  let [total , setTotal] = useState(0);
  useEffect(() => {
    axios.get("https://backendhanderler.onrender.com/getCurrentTotalsg").then((res) => {
      setTotal(res.data.data);
    })
  }, []);
 
  return (
    <div
      style={{
        opacity : show ? 0 : 1,
        delay : show ? "0s" : "0.4s",
        pointerEvents : show ? "none" : "auto",
      }}
    >
      <div
        className="block"
        style={{
          height: "1.2svh",
        }}
      />
      <div
        style={{
          position: "relative",
          width: "30svh",
          height: "30svh",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          src="/2d/yourscore.png"
          alt="bagsentense"
          className="spinner"
          style={{
            width: 30 * 1.06 + "svh",
            height: "30svh",
            display: "block",
          }}
        />

        <img
          src="/2d/gold.png"
          alt="sun"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "135%",
            height: "135%",
            transform: "translate(-50%, -50%)",
          }}
        />

        <h1
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
          }}
        >
          {window?.captureScore?.length ?? 0}
        </h1>
      </div>

      <div
        className="block"
        style={{
          height: "2svh",
        }}
      />

      <h6
        style={{
          textAlign: "center",
          fontFamily: "HelveticaLTPro-Bold",
          color: "white",
          fontSize: "1rem",
          lineHeight: "1.1rem",
          letterSpacing: "0.06rem",
        }}
      >
        TOTAL TABBYS SEND<br/>
        NATIONWIDE {total + parseInt(window?.captureScore?.length ?? 0)}
      </h6>


      <div
        className="block"
        style={{
          height: "1.5svh",
        }}
      />

      <h6
        style={{
          textAlign: "center",
          fontFamily: "HelveticaLTPro-Bold",
          color: "white",
          fontSize: "1rem",
          lineHeight: "1.2rem",
          letterSpacing: "0.06rem",
          // textTransform: "uppercase",
        }}
      >
        Up your ranking by playing again!<br/> Simply re-login to send more Tabbys. <br/>

Player with the most number <br/>of Tabbys sent wins the grand prize
      </h6>
      <div
        className="block"
        style={{
          height: "1.2svh",
        }}
      />

      <h6>T&Cs Apply.</h6>

      <div
          style={{
            height: "1.5svh", 
          }}
        />
      <Button
        onClick = {() => {
          window.location.href = "/";
        }}
        style={{
          pointerEvents : canClick ? "auto" : "none",
        }}
        id={"playAgain"}
      >PLAY AGAIN</Button>
      <Button
        style={{
          pointerEvents : canClick ? "auto" : "none",
        }}
        onClick = {() => {
          if (navigator.share) {
            navigator
              .share({
                title: `PLAY THE COACH GAME`,
                url: window.location.origin,
              })
              .then(() => {
                console.log("Thanks for sharing!");
              })
              .catch(console.error);
          }
        }}
        id={"shareWithFriends"}
      >SHARE WITH FRIENDS</Button>
      <Button
        id={"leaderboard"}
        onClick = {() => {
          setShow(true);
        }}
        style={{
          pointerEvents : canClick ? "auto" : "none",
        }}
      >LEADERBOARD</Button>
    </div>
  );
};

const B = ({
  show,
  setShow,
}) => {
  const {uuid , recordEvent} = useSuperfan();
  const [objs, setObjs] = useState(null);
  const {totalClick} = useStore();
  const { getLeaderboard } = useSuperfan();

  useMemo(async() => {
    let arr = await getLeaderboard(100);
    let _arr = [];

    arr.forEach((element) => {
      let {dataCollection , notValid} = element;
      if(!notValid && dataCollection?.FIRSTNAME && element.gameDatas.dataframe.length === element.score){
        _arr.push({name:dataCollection?.FIRSTNAME ?? "unknow", score:element.score});
      }
    });

    setObjs(_arr.slice(0, 20));
  }, [totalClick]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#E5918A",
        position: "absolute",
        top: 0,
        opacity : show ? 1 : 0,
        transition : "0.8s",
        delay : show ? "0.4s" : "0s",
        pointerEvents : show ? "auto" : "none",
      }}
    >
      <div
        className="block"
        style={{
          height: "1.5svh",
        }}
      />

      <div
        className="imgContainer"
        style={{
          margin: "auto",
        }}
      >
        <h6
          style={{
            textAlign: "center",
          }}
        >
          Congratulations! <br />
          You’re one of the top players <br />
          in your area.
        </h6>

        <div
          className="block"
          style={{
            height: "3svh",
          }}
        />
        <div
          style={{
            height : "33svh",
            overflowY : "scroll",
          }}
        >
          {objs && objs.map((info, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor : info.heightlight ? "yellow" : "transparent",
                  marginBottom : "0.8rem",
                }}
                className={`leaderBroadItem${index}`}
                key={index}
              >
                <h6
                  style={{
                    width: "1rem",
                    fontFamily: "HelveticaLTPro-Bold",
                  }}
                >{index + 1}</h6>
                
                <h6
                  style={{
                    fontFamily: "HelveticaLTPro-Bold",
                  }}
                >{info.name.slice(0 , 10) }</h6>

                <h6
                    style={{
                      fontFamily: "HelveticaLTPro-Bold",
                    }}
                >{info.score}</h6>
              </div>
            );
          })}
        </div>

        <div
          style={{
            height: "2svh", 
          }}
        />
        
        <Button
          id={"back2"}
          onClick = {() => {
            setShow(false);
          }}
        >BACK</Button>
        <Button
          id={"playAgain2"}
          onClick = {() => {
            window.location.href = "/";
          }}
        >PLAY AGAIN</Button>
        <div
          style={{
            height: "1.5svh", 
          }}
        />
        <h6
          style={{
            textDecoration: "underline",
            fontSize: "1.1rem",
            letterSpacing: "0.1rem",
          }}
          id="shopHoliday2"
          onClick={() => {
            recordEvent("shopHoliday2")
            window.open("https://singapore.coach.com/bags/tabby.html", "_blank");
          }}
        >SHOP THE <br/>LATEST COLLECTION</h6>
      </div>
    </div>
  );
};

export default LeaderBroad;
