import Phaser from "phaser";
import { Bootstrap } from "./bootstrap.js";
import { Landing } from "./landing.js";
import { Playscene } from "./playscene.js";
import React, { useEffect } from "react";
import { useSuperfan } from "@pikabobalex/superfan-module";
import { Debug } from "./debug.js";

const config = {
  type: Phaser.AUTO,
  parent: "phaser-container",
  transparent: true,
  width: window.innerWidth * window.devicePixelRatio,
  height: window.innerHeight * window.devicePixelRatio,
  scale: {
    mode: Phaser.Scale.ScaleModes.FIT,
  },
  physics: {
    default: "arcade",
    arcade: {
      gravity: { y: 0 },
      debug: true,
    },
  },

  scene: [Bootstrap, Landing, Playscene , Debug],
};

const IoPhaser = React.forwardRef((props, ref) => {
  useEffect(() => {
    const phaserGame = new Phaser.Game(config);
    ref.current = phaserGame;

    return () => {
      phaserGame.destroy(true);
    };
  }, []);

  const { recordGameData, uuid , getLeaderboard , updateScore } = useSuperfan();

  useEffect(() => {
    if (recordGameData) {
      window.recordGameData = recordGameData;
      window.uuid = uuid;

      window.getLeaderboard = async()=>{
        let arr = await getLeaderboard(100);
        let _arr = [];

        arr.forEach((element) => {
          let {dataCollection , notValid} = element;
          if(!notValid && dataCollection?.FIRSTNAME && element.gameDatas.dataframe.length === element.score){
            _arr.push({name:dataCollection?.FIRSTNAME ?? "unknow", score:element.score});
          }
        });

        return _arr.slice(0,20);
      };
      window.updateScore = updateScore;
    }
  }, []);

  return (
    <div
      id="phaser-container"
      style={{
        position: "absolute",
        top: "0",
        left: "50%",
        zIndex: "1",
        width: "100vw",
        height: "100svh",
        overflow: "hidden",
        transform: "translateX(-50%)",
      }}
    ></div>
  );
});

export default IoPhaser;
