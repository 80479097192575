import { Wraper } from "./helper";
import { Button, ButtonRound, Input, Terms } from "./components";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useStore } from "../store";
import { useRef } from "react";
import { useSuperfan } from "@pikabobalex/superfan-module";
const FormPage = (props) => {
  const { phaserRef } = props;
  const { submitForm , recordEvent } = useSuperfan();
  const nav = useNavigate();
  const formRef = useRef(null);
  const { setInfo, setplayAnimation } = useStore();
  const [info, addInfo] = useState({
    EMAIL: null,
    FIRSTNAME: null,
    LASTNAME: null,
    MOBILE: null,
    countryCode: "+65",
    terms: null,
  });

  return (
    <Wraper
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
      }}
    >
      <div
        className="formContainer"
        ref={formRef}
        style={{
          backgroundColor: "#f4b404",
          padding: "0 3rem", // Adjust the padding as needed
          borderRadius: "15px 15px 0 0", // Optional: Add border-radius for rounded corners
          width: "90%", // Adjust the width as needed
          height: "70svh", // Adjust the height as needed
          position: "relative",
          boxShadow: "0 0 1rem rgba(0,0,0,0.2)",
          transition: "0.8s",
        }}
      >
        <div className="block" style={{ height: "8%" }} />

        <h2>
          BUILD THE MOST
          <br /> COURAGE AND WIN AN <br />
          ALL-NEW QUILTED TABBYS
        </h2>

        <div className="block" style={{ height: "3%" }} />

        <Input
          placeholder="FIRST NAME"
          onChange={(e) => {
            addInfo((prevInfo) => ({
              ...prevInfo,
              FIRSTNAME: e.target.value,
            }));
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Input
            placeholder="+65"
            size="20%"
            type="number"
            onChange={(e) => {
              addInfo((prevInfo) => ({
                ...prevInfo,
                countryCode: e.target.value,
              }));
            }}
          />
          <Input
            placeholder="MOBILE"
            size="75%"
            type="number"
            onChange={(e) => {
              addInfo((prevInfo) => ({
                ...prevInfo,
                MOBILE: e.target.value,
              }));
            }}
          />
        </div>
        <Input
          placeholder="EMAIL"
          onChange={(e) => {
            addInfo((prevInfo) => ({
              ...prevInfo,
              EMAIL: e.target.value,
            }));
          }}
        />

        <div className="block" style={{ height: "3%" }} />
        <Terms terms={info?.terms ?? false} setInfo={addInfo} />

        <div className="block" style={{ height: "5%" }} />

        <h2
          style={{
            fontSize: "1.1rem",
            textDecoration: "underline",
          }}
          id="submitForm"
          onClick={() => {
            if(info.FIRSTNAME === null || info.MOBILE === null || info.terms === null || info.EMAIL === null){
              alert("Please fill in all the fields and accept the terms and conditions to continue");
              return;
            }

            // scroll to top
            window.scrollTo(0,0);
            localStorage.setItem("info2", true);
            recordEvent("submitForm");
            formRef.current.style.opacity = 0;
            
            submitForm(info);

            setTimeout(()=>{
              phaserRef.current.scene.scenes[1].sceneTransition();
            }, 500);
            setTimeout(() => {
              nav("/gameplay");
            }, 1000);
            // this.game.scene.start("playscene");
          }}
        >
          SIGN-UP / RE-LOGIN
        </h2>
      </div>
    </Wraper>
  );
};

export default FormPage;
